  .CMSR-review-container {
    padding: 40px;
    background-color: var(--light-color);
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
  }
  
  .CMSR-submission-details {
    margin-bottom: 30px;
    background-color: var(--secondary-color);
    padding: 20px;
    border-radius: 10px;
  }
  
  .CMSR-form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .CMSR-label {
    font-weight: bold;
    color: var(--dark-color);
    margin-right: 20px;
    width: 30%;
  }
  
  .CMSR-static-field {
    padding: 10px;
    background-color: var(--light-color);
    border-radius: 5px;
    width: 70%;
  }
  
  .CMSR-input-field {
    width: 70%;
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    background-color: var(--light-color);
  }
  
  .CMSR-feedback-field {
    width: 70%;
    height: 100px;
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    background-color: var(--light-color);
    resize: none;
  }
  
  .CMSR-button-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .CMSR-save-btn {
    padding: 12px 20px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--secondary-color);
    cursor: pointer;
  }
  
  .CMSR-save-btn:hover {
    background-color: var(--primary-color-dark);
  }
  