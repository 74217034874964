.sidebar {
    background-color:#E8EAE0;
    min-height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    min-width: 200px;
    max-width: 200px;
  }
  
  .logo {
    margin-bottom: 30px;
  }
  
  .logo img {
    width: 150px;
    height: auto;
  }
  
  .menu {
    list-style: none;
    padding: 0;
    width: 100%;
  }
  
  .menu li {
    width: 100%;
  }
  
  .menu li a {
    display: flex;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    color: #747C80;
    width: 100%;
    transition: background-color 0.3s;
  }
  
  .menu li a:hover {
    border-radius: 4px;
    background-color: #80991d5b;
    color: #637A30;
  }

  .active {
    border-radius: 4px;
    background-color:#80991d5b;
    color: #637A30 !important;
  }

  
  
  .icon {
    margin: 0 20px 0 10px;
    font-size: 18px;
  }
  