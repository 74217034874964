.CMS-CD-career-development-page {
  padding: 20px;
  background-color: var(--light-color);
}

.CMS-CD-h1, .CMS-CD-h4 {
  text-align: center;
  color: var(--primary-color);
}

.CMS-CD-articles-section, .CMS-CD-mentorship-section {
  margin-bottom: 40px;
}

.CMS-CD-articles-section>h4, .CMS-CD-mentorship-section>h4 {
    color: var(--primary-color);
    margin-bottom: 15px;
  }

.CMS-CD-articles-container, .CMS-CD-mentorship-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.CMS-CD-article-card, .CMS-CD-mentorship-card {
  background-color: var(--secondary-color); 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.CMS-CD-article-image, .CMS-CD-mentorship-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.CMS-CD-learn-more-btn, .CMS-CD-enroll-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: var(--primary-color); /* Buttons use the primary color */
  color: var(--light-color); /* Button text uses light color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.CMS-CD-learn-more-btn:hover, .CMS-CD-enroll-btn:hover {
  background-color: var(--primary-color-dark); /* Darker shade for hover effect */
}

.CMS-CD-price {
  margin-top: 10px;
}

.CMS-CD-discounted-price {
  color: red; /* Keeping the red color for discounted price */
  font-weight: bold;
}

.CMS-CD-original-price {
  text-decoration: line-through;
  margin-left: 10px;
  color: var(--dark-color); /* Original price uses dark color */
}
