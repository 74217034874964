@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #637A30;
    --primary-color-dark: #4f5f26;
    --secondary-color: #E1E2D9;
    --light-color: #f5f7fa;
    --dark-color: #333;
}

* {
    font-size: 14px;
    box-sizing: border-box;
    color: var(--dark-color);
    font-family: "Poppins", sans-serif;}


.CMS_main {
    display: flex;
}

.CMS_main-content {
    padding: 70px 10px 10px 10px;
    width: 100%;
    background-color: var(--light-color);
}