  .CMS-schedule-schedule-container {
    padding: 0 40px;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
  }
  
  .CMS-schedule-schedule-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .CMS-schedule-schedule-table th,
  .CMS-schedule-schedule-table td {
    border: 1px solid var(--primary-color);
    padding: 12px;
    text-align: left;
  }
  
  .CMS-schedule-schedule-table th {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }
  
  .CMS-schedule-schedule-table td {
    background-color: var(--secondary-color);
    color: var(--dark-color);
  }
  
  .CMS-schedule-schedule-table a {
    color: var(--primary-color);
    text-decoration: underline;
  }
  
  .CMS-schedule-schedule-table a:hover {
    color: var(--primary-color-dark);
  }
  
  /* Schedule New Conference Form */
  .CMS-schedule-schedule-title{
    margin: 20px 0;
  }
  .CMS-schedule-schedule-form {
    margin-top: 10px;
    padding: 20px;
    background-color: var(--secondary-color);
    border-radius: 8px;
  }
  
  .CMS-schedule-form-group {
    margin-bottom: 20px;
  }
  
  .CMS-schedule-form-group label {
    display: block;
    color: var(--primary-color);
    margin-bottom: 8px;
  }
  
  .CMS-schedule-form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    background-color: var(--light-color);
    color: var(--dark-color);
  }
  
  .CMS-schedule-button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .CMS-schedule-button:hover {
    background-color: var(--primary-color-dark);
  }
  