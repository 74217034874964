.conference-table {
    width: 80%;
    border-collapse: separate;
    margin: 20px 0;
    border-spacing: 0;
    border-radius: 5px;
    overflow: hidden;
  }

  .CMS_dashboard-head{
    display: flex;
    justify-content: center;
  }
  
  .conference-table th,
  .conference-table td {
    border: 1px solid var(--secondary-color);
    padding: 8px;
    text-align: left;
  }
  
  .conference-table th {
    background-color: var(--primary-color-dark);
    color: var(--secondary-color);
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .conference-table tbody tr:nth-child(even) {
    background-color: var(--light-color);
  }
  
  .conference-table tbody tr:hover {
    background-color: var(--secondary-color);
  }
  
  .action-btn {
    padding: 6px 12px;
    margin-right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--primary-color);
  }
  
  .action-btn:hover {
    color: var(--primary-color-dark);
  }
  
  .view-btn {
    color: #3498db;
  }
  
  .edit-btn {
    color: #2ecc71;
  }
  
  .delete-btn {
    color: #e74c3c;
  }
  
  .action-btn:focus {
    outline: none;
  }