
.container-fluid {
  padding: 0;
}

.image-side {
  background-image: url('https://via.placeholder.com/800x800');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--primary-color);
}

.form-side {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-container {
  max-width: 400px;
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.form-title {
  text-align: center;
  margin-bottom: 30px;
  color: var(--primary-color) !important;
  font-weight: 700;
  font-size: 1.8rem;
}

.form-control {
  background-color: var(--light-color) !important;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  font-size: 1rem;
  margin-bottom: 15px;
  transition: border-color 0.3s;
}

.form-control:focus {
  border-color: var(--primary-color) !important;
  box-shadow: none;
}


.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  font-weight: 600;
  padding: 10px 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: var(--primary-color-dark) !important; 
  border-color: var(--primary-color-dark) !important;
}

.btn-link {
  color: var(--primary-color) !important;
  text-decoration: none;
  font-weight: 500;
  padding: 0;
  transition: color 0.3s;
}

.btn-link:hover {
  color: var(--primary-color-dark) !important; 
  text-decoration: underline;
}

.text-center {
  color: var(--dark-color);
}

@media (max-width: 767px) {
  .image-side {
    display: none;
  }
  .form-side {
    width: 100%;
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .image-side {
    background-color: var(--primary-color);
  }
}
