.CMS-PROFILE-profile-page {
    margin: 50px auto;
    width: 50%;
    background-color: var(--bg-color);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .CMS-PROFILE-title {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 30px;
  }
  
  .CMS-PROFILE-profile-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .CMS-PROFILE-form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .CMS-PROFILE-form-group label {
    font-size: 1rem;
  }
  
  .CMS-PROFILE-form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    font-size: 1rem;
  }
  
  .CMS-PROFILE-form-group input:focus {
    outline: none;
    border-color: var(--button-bg-color);
  }
  
  .CMS-PROFILE-submit-btn {
    padding: 10px 20px;
    background-color: var(--button-bg-color);
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    align-self: center;
  }
  
  .CMS-PROFILE-submit-btn:hover {
    background-color: var(--primary-color);
  }
  