  .CMS-virtual-conference-container {
    padding: 40px;
    background-color: var(--light-color);
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .CMS-live-streams, .CMS-recorded-sessions, .CMS-qa-section, .CMS-chat-section {
    margin-top: 30px;
  }
  
  .CMS-live-streams h3, .CMS-recorded-sessions h3, .CMS-qa-section h3, .CMS-chat-section h3 {
    color: var(--primary-color-dark);
  }
  
  .CMS-live-streams ul, .CMS-recorded-sessions ul {
    list-style: none;
    padding: 0;
  }
  
  .CMS-live-streams li, .CMS-recorded-sessions li {
    margin-bottom: 15px;
  }
  
  .CMS-live-streams a, .CMS-recorded-sessions a {
    color: var(--primary-color);
    text-decoration: underline;
  }
  
  .CMS-live-streams a:hover, .CMS-recorded-sessions a:hover {
    color: var(--primary-color-dark);
  }
  
  .CMS-card-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);

  }
  .CMS-card{
    cursor: pointer;
    max-width: 300px;
  }
  .CMS-card>img{
    width: 350px;
    height: 200px;
  }
  .CMS-card-section h3{
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  /* Q&A Section */
  .CMS-qa-form {
    margin-top: 20px;
    padding: 20px;
    background-color: var(--secondary-color);
    border-radius: 8px;
  }
  
  .CMS-form-group {
    margin-bottom: 20px;
  }
  
  .CMS-form-group label {
    display: block;
    color: var(--primary-color);
    margin-bottom: 8px;
  }
  
  .CMS-form-group input, .CMS-form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    background-color: var(--light-color);
    color: var(--dark-color);
  }
  
  .CMS-form-group textarea {
    resize: none;
    height: 100px;
  }
  
  /* Chat Section */
  .CMS-chat-section {
    margin-top: 30px;
  }
  
  .CMS-button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
  }
  
  .CMS-button:hover {
    background-color: var(--primary-color-dark);
  }
  