.header {
    position: fixed;
    width: calc(100% - 200px);
    top: 0;
    left: 200px;
    height: 60px;
    background-color: #f9ffec;
    color: #637A30;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 20px;
    z-index: 1000;
  }
  
  .profile-section {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .profile-name {
    margin-right: 10px;
    font-size: 16px;
    color: #637A30; /* Updated text color */
  }
  
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .settings-icon {
    font-size: 20px;
    cursor: pointer;
    color: #637A30; /* Updated icon color */
  }
  
  .settings-icon:hover {
    color: #50621e; /* Darker shade for hover effect */
  }
  