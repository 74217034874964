  .conference-details-container {
    padding: 40px;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
  }
  
  .conference-section {
    margin-bottom: 20px;
  }
  
  .conference-label {
    font-size: 18px;
    font-weight: bold;
    color: var(--dark-color); /* Dark color */
  }
  
  .conference-text, .conference-description {
    font-size: 16px;
    color: var(--primary-color); /* Primary text color */
    margin-top: 5px;
  }
  
  .conference-description ul {
    margin-left: 20px;
    color: var(--primary-color);
  }
  
  .schedule-call-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .schedule-section, .call-section {
    display: flex;
    flex-direction: column;
    width: 45%;
    gap: 10px;
  }
  
  .schedule-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    margin-top: 5px;
    color: var(--primary-color);
  }
  
  .send-notification-btn {
    padding: 10px;
    background-color: transparent;
    margin-top: 2px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-notification-btn:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  
  .register-btn, .save-btn {
    padding: 12px 20px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    font-size: 16px;
    cursor: pointer;
  }
  
  .register-btn:hover, .save-btn:hover {
    background-color: var(--primary-color-dark);
  }
  