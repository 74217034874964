
  .CMS-SM-submission-container {
    padding: 40px;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
  }
  
  .CMS-SM-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  .CMS-SM-form-row-actions{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .CMS-SM-table th,
  .CMS-SM-table td {
    padding: 12px;
    border: 1px solid var(--primary-color);
    text-align: left;
  }
  
  .CMS-SM-table th {
    background-color: var(--primary-color-dark);
    color: var(--secondary-color);
  }
  
  .CMS-SM-table tr:hover {
    background-color: var(--secondary-color);
    cursor: pointer;
  }
  
  .CMS-SM-submission-details {
    padding: 20px;
    background-color: var(--secondary-color);
    border-radius: 10px;
  }
  
  .CMS-SM-form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .CMS-SM-label {
    font-weight: bold;
    color: var(--dark-color);
    margin-right: 20px;
  }
  
  .CMS-SM-static-field {
    padding: 10px;
    background-color: var(--light-color);
    border-radius: 5px;
    width: 70%;
  }
  
  .CMS-SM-feedback-field {
    width: 70%;
    height: 80px;
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    background-color: var(--light-color);
    resize: none;
  }
  
  .CMS-SM-select-status {
    width: 70%;
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    background-color: var(--light-color);
  }
  
  .CMS-SM-degree-options label {
    margin-right: 10px;
  }
  
  .CMS-SM-button-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .CMS-SM-save-btn {
    padding: 12px 20px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--secondary-color);
    cursor: pointer;
  }
  
  .CMS-SM-save-btn:hover {
    background-color: var(--primary-color-dark);
  }
  