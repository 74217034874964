
  .CMS-papers-submission-container {
    padding: 40px;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
  }
  
  .CMS-papers-deadline-section {
    margin-bottom: 20px;
  }
  
  .CMS-papers-degree-program{
    display: flex;
    flex-direction: column;
  }

  .CMS-papers-days-left {
    color: red;
    font-weight: bold;
  }
  
  .CMS-papers-form-container {
    margin-bottom: 40px;
  }
  
  .CMS-papers-form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .CMS-papers-input-field {
    width: 48%;
    padding: 10px;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    font-size: 16px;
    color: var(--primary-color);
  }
  
  .CMS-papers-textarea {
    width: 100%;
    height: 80px;
  }
  
  .CMS-papers-degree-label {
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--dark-color);
  }
  
  .CMS-papers-degree-options label {
    margin-right: 10px;
  }
  
  .CMS-papers-upload-section {
    margin-top: 20px;
  }
  
  .CMS-papers-upload-box {
    border: 2px dashed var(--primary-color);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    color: var(--primary-color);
  }
  .CMS-upload-section{
    display: flex;
    flex-direction: column;
  }
  .CMS-papers-button-container {
    display: flex;
    justify-content: flex-start;
  }
  
  .CMS-papers-upload-btn {
    padding: 12px 20px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--secondary-color);
    cursor: pointer;
  }
  .CMS-papers-upload-btn>input{
    opacity: 0;
    cursor: pointer;
  }
  .CMS-papers-upload-btn:hover {
    background-color: var(--primary-color-dark);
  }
  
  .CMS-papers-guidelines-section, .CMS-papers-faq-section {
    margin-top: 40px;
  }
  
  .CMS-papers-guidelines-section ul {
    list-style-type: disc;
    margin-left: 20px;
    color: var(--primary-color);
  }
  
  .CMS-papers-faq-section h3, .CMS-papers-guidelines-section h3 {
    color: var(--dark-color);
  }
  
  .CMS-papers-faq-section p {
    margin-bottom: 10px;
    color: var(--primary-color);
    cursor: pointer;
  }

  .CMS-degree-program{
    display: flex;
    flex-direction: column;
  }
  