.CMS-REG-registration-page {
    padding: 40px;
    margin: 0 auto;
    background-color: var(--light-color);
    border-radius: 10px;
}

.CMS-REG-h1 {
    text-align: center;
    color: var(--dark-color);
}

.CMS-REG-registration-form {
    display: flex;
    flex-direction: column;
}

.CMS-REG-registration-form h4{
    color: var(--primary-color);
}

.CMS-REG-form-group {
    margin-bottom: 20px;
    width: 30%;
}
.CMS-REG-form-group textarea{
    width: 100%;
    resize: vertical;
}

.CMS-REG-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: var(--dark-color);
}

.CMS-REG-form-group input,
.CMS-REG-form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    font-size: 16px;
    color: var(--primary-color);
}

.CMS-REG-form-group input:focus,
.CMS-REG-form-group select:focus {
    border-color: var(--primary-color);
    outline: none;
}

.CMS-REG-submit-btn {
    padding: 12px 20px;
    background-color: var(--primary-color);
    color: var(--light-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.CMS-REG-submit-btn:hover {
    background-color: var(--primary-color-dark);
}

.CMS-REG-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
